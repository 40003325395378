import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  message,
  Layout,
  Typography,
} from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { Container } from "react-bootstrap";

function Header({}) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="header">
        <Container>
          <Row align="middle" className="mb-2">
            <Col span={12} md={4}>
              <div className="logo">
                <img src={Logo} alt="Logo" className="img-fluid" />
              </div>
            </Col>

            <Col span={12} md={20} className="contact-info">
              <Button
                onClick={() => navigate("/login")}
                className="join-btn-neww"
              >
                Login
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Header;
