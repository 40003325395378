import React, { useEffect, useState } from "react";
import {
  Card,
  Avatar,
  Button,
  Row,
  Col,
  Typography,
  Divider,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Checkbox,
} from "antd";
import {
  EditOutlined,
  MailOutlined,
  PhoneOutlined,
  CalendarOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import UserIcon from "../../assets/images/avatar.png";
import PhoneInput from "react-phone-input-2";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import { useForm } from "antd/lib/form/Form";
import { useAppContext } from "../../context/AppContext";
import UploadImage from "./UploadImage";

const { Title, Text } = Typography;

const ProfileIndex = () => {
  const [form] = useForm();
  const [user, setUser] = useState();
  const [image, setImage] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const { request } = useRequest();
  const [isCorporation, setIsCorporation] = useState();
  const [mobileNumber, setMobileNumber] = useState({
    country_code: "",
    mobile_number: "",
  });

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleChange = (value, data) => {
    var country_code = data?.dialCode;
    console.log(data, value, "-----");
    setMobileNumber({
      country_code: country_code,
      mobile_number: value?.slice(data?.dialCode?.length),
    });
  };
  const { setPageHeading } = useAppContext();

  const getProfile = () => {
    request({
      url: apiPath.profile,
      method: "GET",
      onSuccess: (data) => {
        setUser(data.data);
        setIsCorporation(data?.data?.is_corporation);
      },
      onError: (err) => {
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  const onEdit = (value) => {
    const { name, email, gender, dob, corporation } = value;
    const payload = { name, email, gender, dob, corporation };
    payload.country_code = mobileNumber.country_code;
    payload.mobile_number = mobileNumber.mobile_number;
    payload.is_corporation = isCorporation;

    request({
      url: apiPath.updateProfile,
      method: "POST",
      data: { ...payload },
      onSuccess: (data) => {
        if (data.statusText == "true") {
          setUser(data.data);
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (er) => {
        ShowToast("Something went wrong!", Severty.ERROR);
      },
    });
    setIsEdit(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: user?.name,
      email: user?.email,
      gender: user?.gender,
      dob: moment(user?.dob),
      corporation: user?.corporation,
      mobile: user?.country_code + user?.mobile_number,
    });
    setImage(user?.image);
    setMobileNumber({
      country_code: user?.country_code,
      mobile_number: user?.mobile_number,
    });
  }, [user]);

  useEffect(() => {
    getProfile();
    setPageHeading("My Profile");
  }, []);

  console.log("Image::::::: ", image);

  return (
    <>
      {!isEdit ? (
        <div className="profile-container">
          <Card className="profile-card home-card">
            <Avatar size={100} src={user?.image || UserIcon} />
            {isEdit && (
              <div className="edit-icon">
                <Button shape="circle" icon={<EditOutlined />} />
              </div>
            )}

            <Title level={3} className="profile-name">
              {user?.name || "Alexim Cagle"}
            </Title>
            <Text className="profile-email">
              {user?.email || "aleximcagle@gmail.com"}
            </Text>

            <Divider />
            <div className="profiles-de">
              <ul className="profile-details">
                <li>
                  <span>Full Name</span>

                  <p>{user?.name || "Alexim Cagle"}</p>
                </li>

                <li>
                  <span>Email Address</span>

                  <p>{user?.email || "aleximcagle@gmail.com"}</p>
                </li>

                <li>
                  <span>Mobile Number</span>
                  <p>
                    +{user?.country_code} {user?.mobile_number}
                  </p>
                </li>

                <li>
                  <span>DOB</span>
                  <p>{user?.dob ? moment(user?.dob).format("ll") : "-"}</p>
                </li>

                <li>
                  <span>Gender</span>
                  <p>
                    {user?.gender == "F"
                      ? "Female"
                      : user?.gender == "M"
                      ? "Male"
                      : "Other"}
                  </p>
                </li>

                <li>
                  <span>Corporation</span>
                  <p>{user?.is_corporation ? user?.corporation : "NA"}</p>
                </li>
              </ul>
            </div>
            <Button
              onClick={() => {
                setIsEdit(true);
                // ShowToast("Done By Tomorrow End!", Severty.INFO);
              }}
              type="primary"
              className="edit-profile-button"
            >
              Edit Profile
            </Button>
          </Card>
        </div>
      ) : (
        <div className="profile-container">
          <Card className="profile-card home-card">
            <div className="upload-img">
              <UploadImage className="" value={image} setImage={setImage} >
              
              </UploadImage>
            </div>
            <Title level={3} className="profile-name">
              {user?.name}
            </Title>
            <Text className="profile-email">{user?.email}</Text>

            <Divider />

            <Form
              id="create"
              form={form}
              layout="vertical"
              className="row-col"
              onFinish={onEdit}
            >
              <Row gutter={[24, 0]}>
                <Col span={24} md={12}>
                  <Form.Item
                    className="username-form"
                    label="Full Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter name!",
                      },
                    ]}
                  >
                    <Input className="form-of-type" placeholder="Enter Name" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    className="username-form"
                    label="Email Address"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                      {
                        max: 255,
                        message: "Email address not more then 255 characters!",
                      },
                      {
                        required: true,
                        message: "Please enter email!",
                      },
                    ]}
                  >
                    <Input
                      disabled
                      className="form-of-type"
                      placeholder="Enter Email ID"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} lg={12} className="flagMobileNumber">
                  <Form.Item
                    className="username-form"
                    label="Mobile Number"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        validator: (rule, value) => {
                          if (!value) {
                            return Promise.reject("Please enter phone number");
                          }
                          if (mobileNumber?.mobile_number?.length < 8 || mobileNumber?.mobile_number?.length > 12) {
                            console.log(mobileNumber)
                            return Promise.reject('Phone number must be between 8 and 12 digits');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      className="form-of-phone"
                      inputProps={{
                        name: "mobile",
                        required: true,
                        autoFocus: false,
                      }}
                      isValid={(value, country) => {
                        if (value.match(/1234/)) {
                          return (
                            "Invalid value: " + value + ", " + country.name
                          );
                        } else if (value.match(/1234/)) {
                          return (
                            "Invalid value: " + value + ", " + country.name
                          );
                        } else {
                          return true;
                        }
                      }}
                      // value={{country_code: mobileNumber.country_code, mobile_number: mobileNumber.mobile_number }}
                      country={"us"}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item className="username-form" label="DOB" name="dob">
                    <DatePicker
                      className="form-of-type date-innn"
                      onChange={onChange}
                      disabledDate={(current) => {
                        return (
                          current &&
                          current > moment(new Date("10-10-2000")).endOf("day")
                        );
                      }}
                    />
                    {/* <Input
                      type="date"
                      className="form-of-type"
                      placeholder="DOB"
                    /> */}
                  </Form.Item>
                </Col>
                <Col span={24} md={24}>
                  <Form.Item
                    label="Gender"
                    name="gender"
                    className="select-form"
                  >
                    <Select
                      className="price-select"
                      placeholder="Select Gender"
                      defaultValue="M"
                      options={[
                        { value: "M", label: "Male" },
                        { value: "F", label: "Female" },
                        { value: "O", label: "Other" },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    className="username-form"
                    label="Is Corporation"
                    name="is_corporation"
                  >
                    <div className="text-start">
                      <Checkbox
                        checked={isCorporation}
                        onChange={(e) => {
                          setIsCorporation(e.target.checked);
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
                {isCorporation && (
                  <Col span={24} md={12}>
                    <Form.Item
                      className="username-form"
                      label="Corporation Name"
                      name="corporation"
                    >
                      <Input
                        className="form-of-type"
                        placeholder="Enter Name"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <div className="edit-footer">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="edit-profile-button"
                >
                  Submit
                </Button>

                <Button
                  type="primary"
                  className="edit-profile-button"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      )}
    </>
  );
};

export default ProfileIndex;
