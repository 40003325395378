import { Image, Menu } from "antd";
import { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import Logo from "../../assets/images/logo.svg";
import TransactionIcon from "../../assets/images/side-nav/transactions-icon.svg";
import WalletIcon from "../../assets/images/side-nav/walleticon.svg";
import UserIcon from "../../assets/images/side-nav/user-line.svg";
import Dash from "../../assets/images/side-nav/dashboard.svg";
import ChangePass from "../../assets/images/side-nav/change-passward.svg";
import SupportIcon from "../../assets/images/side-nav/customer-support.svg";
import Log from "../../assets/images/side-nav/logout.svg";
import DeleteModal from "../DeleteModal";
import { Severty, ShowToast } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";

export const menuItems = [
  {
    key: "dashboard-manager",
    path: "/dashboard",
    icon: Dash,
    label: "Dashboard",
  },
  // {
  //   key: "transactions",
  //   label: "Transactions",
  //   icon: TransactionIcon,
  //   path: "/transactions",
  // },
  {
    key: "wallet",
    label: "Wallet",
    icon: WalletIcon,
    path: "/wallet",
  },
  // {
  //   key: "Available Currencies",
  //   path: "/available-currencies",
  //   icon: ChangePass,
  //   label: "Available Currencies",
  // },
  {
    key: "My Profile",
    path: "/my-profile",
    icon: UserIcon,
    label: "My Profile",
  },
  {
    key: "Change Password",
    path: "/change-password",
    icon: ChangePass,
    label: "Change Password",
  },
  {
    key: "Help & Support",
    path: "/help-support",
    icon: SupportIcon,
    label: "Help & Support",
  },
  {
    key: "About Us",
    path: "/about",
    icon: SupportIcon,
    label: "About Us",
  },
  // {
  //   key: "Content",
  //   path: "/content",
  //   icon: SupportIcon,
  //   label: "Content",
  // },
];

function Sidenav({ color }) {
  const { userProfile, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState("vertical");

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const showDeleteConfirm = () => {
    setIsLogoutModalVisible(true);
  };

  const renderTitle = (item) => {
    return (
      <>
        <Image preview={false} src={item.icon} />
        <span className="label">{item.label}</span>
      </>
    );
  };

  useEffect(() => {
    const items = menuItems;
    setMenuLinks(items);
  }, [userProfile]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode("inline");
      } else {
        setMenuMode("vertical");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <>
        <div className="brand-logo">
          <NavLink to="/dashboard" className="imgOuter">
            <img className="" src={Logo} alt="" />
          </NavLink>
        </div>
        <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain">
          {menuLinks.map((item) => {
            if (item.children) {
              return (
                <>
                  <Menu.SubMenu
                    className="vehicle-icon"
                    key={item.key}
                    title={
                      <>
                        <span className="icon">
                          <Image preview={false} src={item.icon} />
                        </span>
                        <span className="label">{item.label}</span>
                      </>
                    }
                  >
                    {item.children.map((child) => (
                      <Menu.Item key={child.key}>
                        <NavLink to={child.path}>{child.label}</NavLink>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                </>
              );
            }

            return (
              <Menu.Item key={item.key} disabled={item?.comming_soon}>
                <NavLink to={item.path}>
                  {renderTitle(item)}&nbsp;
                  {item?.comming_soon ? "(Comming Soon)" : ""}
                </NavLink>
              </Menu.Item>
            );
          })}

          <Menu.Item onClick={showDeleteConfirm}>
            <NavLink to={"#"}>
              <>
                <Image preview={false} src={Log} />
                <span className="label">Logout</span>
              </>
            </NavLink>
          </Menu.Item>
        </Menu>
      </>

      {isLogoutModalVisible && (
        <DeleteModal
          title={"Logout"}
          subtitle={"Are you sure you want to logout?"}
          show={isLogoutModalVisible}
          hide={() => setIsLogoutModalVisible(false)}
          onOk={() => {
            ShowToast("Logout Successfully!", Severty.SUCCESS);
            navigate("/home");
            logout();
          }}
        />
      )}
    </>
  );
}

export default Sidenav;
