// GoldPriceChart.js
import React, { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement);

const GoldPriceChart = ({ dataSet }) => {
  const chartRef = useRef(null);
  const dotRef = useRef(null);

  const data = {
    labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
    datasets: [
      {
        label: "Gold Price (USD)",
        // data: [
        //   1800, 1805, 1795, 1810, 1820, 1815, 1825, 1830, 1820, 1835, 1845,
        //   1840, 1850, 1855, 1845, 1860, 1870, 1865, 1875, 1880, 1870, 1885,
        //   1895, 1890,
        // ],
        data: dataSet?.length
          ? [...dataSet]
          : [
              1800, 1805, 1795, 1810, 1820, 1815, 1825, 1830, 1820, 1835, 1845,
              1840, 1850, 1855, 1845, 1860, 1870, 1865, 1875, 1880, 1870, 1885,
              1895, 1890,
            ],
        borderColor: "gold",
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: { color: "rgba(255, 255, 255, 0.1)" },
        ticks: { color: "white" },
      },
      y: {
        position: "right",
        grid: { color: "rgba(255, 255, 255, 0.1)" },
        ticks: {
          color: "white",
          callback: (value) => `$${value}`,
        },
        afterFit: (scale) => {
          scale.width = 70;
        },
      },
    },
    plugins: { legend: { display: false } },
    elements: { line: { tension: 0.4 } },
    animation: {
      duration: 2000,
      easing: "easeInOutQuart",
      onComplete: () => {
        positionGlowingDot();
      },
    },
    layout: { padding: { right: 20 } },
  };

  const positionGlowingDot = () => {
    if (chartRef.current) {
      const chartArea = chartRef.current.chartArea;
      const meta = chartRef.current.getDatasetMeta(0);
      const lastPoint = meta.data[meta.data.length - 1];
      const dot = dotRef.current;
      dot.style.left = `${lastPoint.x}px`;
      dot.style.top = `${lastPoint.y}px`;
    }
  };

  useEffect(()=>{
    console.log(dataSet);
  },[dataSet]);

  useEffect(() => {
    window.addEventListener("resize", positionGlowingDot);
    return () => window.removeEventListener("resize", positionGlowingDot);
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.chartContainer}>
        <Line
          style={{ margin: "10px" }}
          ref={chartRef}
          data={data}
          options={options}
        />
        <div ref={dotRef} style={styles.glowingDot}></div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh',
    margin: 0,
    backgroundColor: "#1a1a1a",
    borderRadius: "15px",
  },
  chartContainer: {
    width: "95%",
    maxWidth: "1200px",
    height: "500px",
    marginTop: 10,
    position: "relative",
  },
  glowingDot: {
    position: "absolute",
    width: "10px",
    height: "10px",
    margin: "10px",
    backgroundColor: "gold",
    borderRadius: "50%",
    animation: "pulse 2s infinite",
    transform: "translate(-50%, -50%)",
  },
};

export default GoldPriceChart;
