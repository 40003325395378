import React, { useEffect, useState } from "react";
import { Card, Form } from "antd";
import { useAppContext } from "../context/AppContext";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import apiPath from "../constants/apiPath";

const ContentIndex = () => {
  const { setPageHeading } = useAppContext();
  const { request } = useRequest();
  const [content, setContent] = useState();

  const getTnC = () => {
    request({
      url: apiPath.content,
      method: "GET",
      onSuccess: (data) => {
        if (data?.status) {
          // ShowToast(data?.message, Severty.SUCCESS);
          setContent(data?.data);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (data) => {
        ShowToast(data?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getTnC();
    setPageHeading("Content");
  }, []);

  return (
    <>
      <Card className=" home-card help-card">
        <div className="help-text">
          <h6>Privacy Policy</h6>
          <div>
            {/* <p dangerouslySetInnerHTML={{ __html: content?.description }} /> */}
          </div>
        </div>
      </Card>
      <Card className=" home-card help-card mt-3">
        <div className="help-text">
          <h6>Terms & Conditions</h6>
          <div>
            {/* <p dangerouslySetInnerHTML={{ __html: content?.description }} /> */}
          </div>
        </div>
      </Card>
    </>
  );
};

export default ContentIndex;
