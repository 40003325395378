import React, { useEffect, useState } from "react";
import Header from "../../components/layout/Header";
import { Row, Col, Button } from "antd";
import OwlCarousel from "react-owl-carousel3";
import { Container } from "react-bootstrap";
import Footer from "../../components/layout/Footer";
import Coin01 from "../../assets/images/smallcoine01.svg";
import Coin02 from "../../assets/images/smallcoine02.svg";
import Coin03 from "../../assets/images/bigcoine01.svg";
import Coin04 from "../../assets/images/bigcoine02.svg";

import ShapeCenter from "../../assets/images/center-rectangle.png";
import ShapeLeft from "../../assets/images/right-rectangle.png";
import ShapeRight from "../../assets/images/left-rectangle.png";
import AboutImg from "../../assets/images/about-image.png";
import FeatureIcon from "../../assets/images/circalfeature.svg";
import RightCheck from "../../assets/images/right-circal.svg";
import QuotexIcon from "../../assets/images/qotion.svg";
import StarIcon from "../../assets/images/Star-list.svg";
import UserIcon from "../../assets/images/avatar.png";
import User1Icon from "../../assets/images/avatar-1.png";
import User2Icon from "../../assets/images/img2.jpeg";
import FacebookIcon from "../../assets/images/fb.svg";
import InstagramIcon from "../../assets/images/insta.svg";
import TwitterIcon from "../../assets/images/twitter.svg";
import Logo from "../../assets/images/logo.svg";
import MapIcon from "../../assets/images/map.svg";
import MailIcon from "../../assets/images/mail-icon.svg";
import CurrencyConvertor from "./CurrencyConvertor";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";

function Home() {
  const { request } = useRequest();
  const [info, setInfo] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const handleInfo = (e) => {
    setInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmit = () => {
    if (!info.email && !info.message) {
      ShowToast("Please fill all details!", Severty.ERROR);
      return;
    }

    const payload = { ...info };
    console.log(payload, " ::::::::::: ___________ paylod");

    request({
      url: apiPath.support,
      method: "POST",
      data: { ...payload },
      onSuccess: (data) => {
        if (data?.statusText == "true") {
          ShowToast(data?.message, Severty.SUCCESS);
          setInfo({
            email: "",
            name: "",
            message: "",
            mobile: "",
          });
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <Header />

      <main className="main-content">
        <section className="banner-sec">
          <Container className="    position-relative">
            <div className="left-coins">
              <img className="coin-first01" src={Coin01} />
              <img className="coin-first02" src={Coin02} />
              <img className="coin-first03" src={Coin03} />
              <img className="coin-first04" src={Coin04} />
            </div>
            <div className="header-top">
              <img className="img-colorshape01" src={ShapeLeft} />
              <img className="img-colorshape02" src={ShapeCenter} />
              <img className="img-colorshape03" src={ShapeRight} />
            </div>
            <Row>
              <Col span={24}>
                <div className="sladir-slaide">
                  <div className="head-headding">
                    <span>Unlock the World of Online Trading</span>
                  </div>
                  <h2>
                    {" "}
                    Trade Smarter,
                    <br /> Not Harder{" "}
                  </h2>
                  <p>
                    {" "}
                    With a strong emphasis on education and customer support, we
                    provide resources to help our users make informed decisions
                    and succeed in their trading journeys. Join us at Trading
                    Platform and take control of your financial future.
                  </p>
                  {/* <Button className="join-btn-neww">Explore</Button> */}
                </div>
              </Col>
            </Row>
            <div className="right-coins">
              <img className="coin-first01" src={Coin01} />
              <img className="coin-first02" src={Coin02} />
              <img className="coin-first03" src={Coin03} />
              <img className="coin-first04" src={Coin04} />
            </div>
          </Container>
        </section>
      </main>

      <section className="rate-section">
        <Container>
          <CurrencyConvertor />
        </Container>
      </section>

      <section className="contact-section">
        <Container>
          <div className="section-headding">
            <h4>Contact Us</h4>
          </div>
          <Row gutter={24}>
            <Col span={24}>
              <div className="contacts-box">
                <h2>Get in touch with us. We're here to assist you.</h2>
                <div className="social-media-icon">
                  <ul>
                    <li>
                      <a>
                        <img src={FacebookIcon} />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={InstagramIcon} />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={TwitterIcon} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col span={24} md={8}>
              <div className="form-inn">
                <input
                  value={info.name}
                  onChange={handleInfo}
                  name="name"
                  type="text"
                  placeholder="Your Name"
                  className="contact-input"
                />
              </div>
            </Col>
            <Col span={24} md={8}>
              <div className="form-inn">
                <input
                  name="email"
                  value={info.email}
                  onChange={handleInfo}
                  type="email"
                  placeholder="Email Address"
                  className="contact-input"
                />
              </div>
            </Col>
            <Col span={24} md={8}>
              <div className="form-inn">
                <input
                  value={info.number}
                  onChange={handleInfo}
                  name="number"
                  type="text"
                  placeholder="Phone Number (optional)"
                  className="contact-input"
                />
              </div>
            </Col>
            <Col span={24}>
              <div className="form-inn">
                <textarea
                  value={info.message}
                  onChange={handleInfo}
                  name="message"
                  placeholder="Message"
                  className="contact-textarea"
                ></textarea>
              </div>
            </Col>
            <div className="contact-form-btn">
              <Button
                onClick={() => onSubmit()}
                type="submit"
                className="submit-button"
              >
                Leave us message
              </Button>
            </div>
          </Row>
        </Container>
      </section>

      <section className="footer-section">
        <Container>
          <Row>
            <Col span={24}>
              <div className="footer-logo">
                <img src={Logo} />
              </div>
            </Col>
            <Col span={24} sm={8} md={8}>
              <div className="footer-social">
                <div className="icon-box">
                  <img src={MapIcon} />
                </div>

                <a>+1 5589 55488 55</a>
              </div>
            </Col>
            <Col span={24} sm={8} md={8}>
              <div className="footer-social">
                <div className="icon-box">
                  <img src={MapIcon} />
                </div>

                <a>A108 Adam Street New York, NY 535022 United States</a>
              </div>
            </Col>
            <Col span={24} sm={8} md={8}>
              <div className="footer-social">
                <div className="icon-box">
                  {" "}
                  <img src={MailIcon} />
                </div>

                <a>info@example.com</a>
              </div>
            </Col>
            <Col span={24}>
              <div className="d-footer-text">
                <a>Privacy Policy</a>
                <a>Terms & Conditions</a>
                <a>Help</a>
                <a>Faq’s</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
}

export default Home;


{/* <section className="abouts-section">
        <Container>
          <div className="section-headding">
            <h4>About us</h4>
          </div>
          <Row gutter={32} className="mt-5">
            <Col span={24} md={12}>
              <div className="about-img">
                <img src={AboutImg} />
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="right-about-outer">
                <h4>Trading Platform</h4>
                <p>
                  Welcome to Trading Kingdom of Gold, your trusted partner in
                  the world of online gold trading. Our platform is designed to
                  empower traders of all experience levels, offering
                  cutting-edge tools, real-time market insights, and seamless
                  access to global financial markets.
                </p>

                <p>
                  At Trading Kingdom, we believe in providing a secure,
                  transparent, and user-friendly environment for our clients.
                  Whether you're a beginner exploring the financial world or an
                  experienced trader looking for advanced features, our platform
                  caters to your needs with powerful trading tools, insightful
                  analytics, and a vast array of assets including stocks,
                  commodities, forex, and cryptocurrencies.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
{/* <section className="Feature-section">
        <Container>
          <div className="section-headding">
            <h4>Feature</h4>
          </div>
          <div className="feature-item">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={20}
              nav={true}
              dots={true}
              center={true}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 3,
                },
              }}
            >
              <div className="item feature-main">
                <div className="feature-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={FeatureIcon}
                      className="feature-image"
                      alt="Feature"
                    />
                  </div>
                  <h6>Real-Time Market Data</h6>
                  <p className="feature-message">
                    Stay ahead of the market with up-to-the-second data, charts,
                    and updates.
                  </p>
                  <div className="feature-listed">
                    <h6>What you will get</h6>
                    <ul>
                      <li>
                        <img src={RightCheck} alt="Check" /> Real-Time Market
                        Data
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Advanced Trading
                        Tools
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Multi-Asset Trading
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Secure and
                        Transparent{" "}
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> User-Friendly
                        Interface
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> 24/7 Customer
                        Support
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item feature-main">
                <div className="feature-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={FeatureIcon}
                      className="feature-image"
                      alt="Feature"
                    />
                  </div>
                  <h6>Secure and Transparent</h6>
                  <p className="feature-message">
                    Our platform prioritizes the safety of your data and funds,
                    including encryption and 2FA authentication.
                  </p>
                  <div className="feature-listed">
                    <h6>What you will get</h6>
                    <ul>
                      <li>
                        <img src={RightCheck} alt="Check" /> Secure and
                        Transparent
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Multi-Asset Trading
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Real-Time Market
                        Data parturient
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Advanced Trading
                        Tools{" "}
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> User-Friendly
                        Interface
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> 24/7 Customer
                        Support
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item feature-main">
                <div className="feature-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={FeatureIcon}
                      className="feature-image"
                      alt="Feature"
                    />
                  </div>
                  <h6>User-Friendly</h6>
                  <p className="feature-message">
                    Make informed trading decisions with accurate and reliable
                    information at your fingertips.
                  </p>
                  <div className="feature-listed">
                    <h6>What you will get</h6>
                    <ul>
                      <li>
                        <img src={RightCheck} alt="Check" /> Secure and
                        Transparent
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Multi-Asset Trading
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Real-Time Market
                        Data parturient
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Advanced Trading
                        Tools{" "}
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> User-Friendly
                        Interface
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> 24/7 Customer
                        Support
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </Container>
      </section>
      <section className="testimonial-section">
        <Container>
          <div className="section-headding">
            <h4>Testimonial</h4>
          </div>
          <div className="testimonial-item">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={20}
              nav={true}
              dots={true}
              center={true}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 3,
                },
              }}
            >
              <div className="item testimonial-main">
                <div className="testimonial-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={QuotexIcon}
                      className="testimonial-image"
                      alt="testimonial"
                    />
                  </div>
                  <h6>
                    Trading on this platform has been a game-changer for me.
                    The user-friendly interface and powerful tools make it easy
                    to track the markets and execute trades in real time.
                  </h6>
                  <div className="star-icon">
                    <img src={StarIcon} />
                  </div>

                  <div className="testimonial-listed">
                    <div className="test-user">
                      <img style={{ borderRadius: 50 }} src={User2Icon} />
                    </div>
                    <div className="testimonial-details">
                      <h6>Brandon Roy</h6>
                      <p>Stock Market Expert</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item testimonial-main">
                <div className="testimonial-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={QuotexIcon}
                      className="testimonial-image"
                      alt="testimonial"
                    />
                  </div>
                  <h6>
                    The flexibility of being able to trade on my phone is a huge
                    plus! Trading Platform allows me to monitor my portfolio and
                    react to market changes no matter where I am{" "}
                  </h6>
                  <div className="star-icon">
                    <img src={StarIcon} />
                  </div>

                  <div className="testimonial-listed">
                    <div className="test-user">
                      <img src={User1Icon} />
                    </div>
                    <div className="testimonial-details">
                      <h6>Micheal Wheeler</h6>
                      <p>Trading User</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item testimonial-main">
                <div className="testimonial-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={QuotexIcon}
                      className="testimonial-image"
                      alt="testimonial"
                    />
                  </div>
                  <h6>
                    I’ve tried several trading platforms, but none compare to
                    Trading Platform. The advanced tools and real-time data have
                    helped me make more informed decisions.{" "}
                  </h6>
                  <div className="star-icon">
                    <img src={StarIcon} />
                  </div>

                  <div className="testimonial-listed">
                    <div className="test-user">
                      <img src={UserIcon} />
                    </div>
                    <div className="testimonial-details">
                      <h6>Dianne Russell</h6>
                      <p>Lead designer</p>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </Container>
      </section> */}
{/* <section className="newsletter-section">
        <Container>
          <Row gutter={32}>
            <Col span={24} md={12}>
              <div className="news-menus">
                <h4>Subcribe to our Newsletter</h4>
                <p>
                  Subscribe for Updates: Stay informed about the latest investor
                  updates, financial results, and announcements by subscribing
                  to our newsletter.{" "}
                </p>
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="news-form-inn">
                <input
                  type="text"
                  placeholder="Phone Number (optional)"
                  className=""
                />
                <Button>Subscribe</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}