let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://kpproduct.s3.us-east-2.amazonaws.com:8080/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  // URL = "https://backend.esteemlink.com/api/";
  URL = "https://admin.esteemlink.com/api/";
} else {
  // URL = "https://backend.esteemlink.com/api/";
  URL = "https://admin.esteemlink.com/api/";
  // URL = "http://localhost:8500/api/";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "app/dashboard",
  latestPrice: "app/auth/latest-price",
  getStockData: "common/dashboard/get-stock-price",
  login: "app/auth/login",
  logout: "app/auth/logout",

  generate2FA: "app/auth/generate-2fa",
  verify2FA: "app/auth/verify-2fa",

  listOrder: "app/order/list",
  viewOrder: "app/order/view",
  placeOrder: "app/order/place-order",

  listTransaction: "app/transaction/list",
  viewTransaction: "app/transaction/view",

  profile: "app/auth/get-profile",
  updateProfile: "app/auth/update-profile",
  changePassword: "app/auth/change-password",

  wallet: "app/wallet/info",
  walletTransaction: "app/wallet/history",

  sendOTP: "app/auth/send-otp",
  verifyOTP: "app/auth/verify-otp",
  resetPassword: "app/auth/reset-password",
  forgotPassword: "app/auth/forgot-password",

  support: "app/support",

  // Content APIs
  content: "common/content",
  notification: "app/notification",
  rating: "app/rating",

  common: {

  },

};

export default apiPath;
