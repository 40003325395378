import {
  Card,
  Col,
  Form,
  Row,
  Select,
  Table,
  Modal,
  Button,
  Input,
  Tag,
  Progress,
  Tooltip,
} from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import SectionWrapper from "../../components/SectionWrapper";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import WalletIcon from "../../assets/images/ion_wallet.svg";
import ExchangeIcon from "../../assets/images/exchange.png";
import GoldIcon from "../../assets/images/bigcoine01.svg";
import moment from "moment";
import { useAuthContext } from "../../context/AuthContext";
import { Option } from "antd/lib/mentions";
import GoldPriceChart from "../../components/StockChart";
import { useAppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import ConfirmOrder from "../../components/ConfirmOrder";
import ChartGold from "../Chart/GoldChart";

function Home() {
  const { request } = useRequest();
  const { userProfile, setUserProfile } = useAuthContext();
  const { setPageHeading } = useAppContext();
  const [error, setError] = useState("");
  const [quality, setQuality] = useState("995");
  const [quantity, setQuantity] = useState();
  const [wallet, setWallet] = useState();
  const [otherWallets, setOtherWallets] = useState([]);
  const [isRise, setIsRise] = useState(true);
  const [bookAmount, setBookAmount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [currency, setCurrency] = useState("USD");
  const [orders, setOrders] = useState([]);
  const [done, setDone] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(wallet?._id ?? "");
  const [count, setCount] = useState(1);
  const sliderRef = useRef(null);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      render: (_, { order_id }) => (
        <span color="white">#{order_id || "-"}</span>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, { quantity }) => {
        return <span>{quantity || 0} KG</span>;
      },
    },
    {
      title: "Quality",
      dataIndex: "quality",
      key: "quality",
      render: (_, { quality }) => {
        return <span>{quality || "-"}</span>;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (_, { currency }) => {
        return <span>{currency}</span>;
      },
    },
    {
      title: "Booked Price",
      dataIndex: "book_amount",
      key: "book_amount",
      render: (_, { book_amount }) => {
        return <span>{book_amount?.toFixed(2)}</span>;
      },
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => {
        return <span>{amount?.toFixed(2)}</span>;
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (_, { status }) => {
    //     let color = status === "pending" ? "blue" : "green";
    //     return (
    //       <Tag color={color} className="text-uppercase">
    //         {status}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (_, { created_at }) => {
        return (
          <span>
            {moment(created_at).format("ll")},{" "}
            {moment(created_at).format("hh:mm A")}
          </span>
        );
      },
    },
  ];

  const handleReset = () => {
    if (!sliderRef.current.classList.contains("unlocked")) return;
    sliderRef.current.classList.remove("unlocked");
    document.getElementById("locker").textContent = "lock_open";
    sliderRef.current.removeEventListener("click", handleReset);
  };

  const getProfile = () => {
    request({
      url: apiPath.profile,
      method: "GET",
      onSuccess: (data) => {
        setUserProfile(data.data);
      },
      onError: (error) => {
        ShowToast(error?.message, Severty.ERROR);
      },
    });
  };

  const fetchOrders = () => {
    request({
      url: apiPath.listOrder,
      method: "GET",
      onSuccess: (data) => {
        setOrders(data.data || []);
        // ShowToast(data.message, Severty.ERROR);
      },
      onError: (err) => {
        // ShowToast(err.message, Severty.ERROR);
      },
    });
  };

  const fetchWalletInfo = (id) => {
    request({
      url: apiPath.wallet + (id ? `/${id}` : ""),
      method: "GET",
      onSuccess: (data) => {
        if (data?.statusText == "true") {
          setWallet(data?.data?.wallet);
          setSelectedWallet(data?.data?.wallet?._id);
          setOtherWallets(data?.data?.otherWallets || []);
          localStorage.setItem("wallet", data?.data?.wallet?._id);
          setCurrency(data?.data?.wallet?.currency);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.error?.response?.message, Severty.ERROR);
      },
    });
  };

  const latestPrice = () => {
    request({
      url: apiPath.latestPrice,
      method: "GET",
      onSuccess: ({ data, statusText, message }) => {
        console.log(data);
        if (statusText === "true") {
          let total =
            userProfile?.gold_purity == "995"
              ? data[currency]?.buy * 0.995
              : data[currency]?.buy * 0.999;
          setBookAmount(total || 0);
          if (data[currency] < bookAmount) {
            setIsRise(false);
          }
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  const getStockData = () => {
    request({
      url: apiPath.getStockData,
      method: "GET",
      onSuccess: (data) => {
        if (data?.statusText === "true") {
          let tempData = data?.data?.map((item) => item?.close?.toFixed(2));
          setStockData(tempData || []);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.message, Severty.ERROR);
      },
    });
  };

  const placeOrder = () => {
    const payload = {
      amount: quantity * bookAmount * 32.148,
      book_amount: bookAmount,
      quantity,
      quality: userProfile?.gold_purity,
      wallet_id: selectedWallet,
    };

    console.log(payload);
    request({
      url: apiPath.placeOrder,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data?.statusText == "true") {
          ShowToast(data.message, Severty.SUCCESS);
          setDone(true);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
        // setIsModalOpen(false);
        setRefresh((x) => !x);
        // setQuantity();
      },
      onError: (err) => {
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchOrders();
    getStockData();
    getProfile();
    let wallet = localStorage.getItem("wallet") || false;
    console.log(wallet, "-------wallet");
    fetchWalletInfo(wallet);
  }, [refresh]);

  useEffect(() => {
    latestPrice();
  }, [currency]);

  useEffect(() => {
    setPageHeading("Dashboard");
  }, []);

  useEffect(() => {
    if (count < 10) {
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 1000);

      return () => clearInterval(timer); // Clear the timer when the component unmounts or when the effect is cleaned up
    }
  }, [count]);

  return (
    <>
      <div className="page-top-space home-card layout-content">
        <div className="mb-24">
          <Row gutter={[24, 24]} className=" ">
            <Col span={24} md={12} xl={8}>
              <div className="walletbox-card">
                <div
                  className="wlt-header"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="wlt-header-inn">
                    <img width={50} src={WalletIcon} />
                    <p>Total Balance</p>
                  </div>
                  {/* {otherWallets?.length > 0 && (
                    <div className=" ">
                      <img
                        onClick={() => setWalletModal(true)}
                        style={{ borderRadius: "20px" }}
                        width={30}
                        src={ExchangeIcon}
                      />
                    </div>
                  )} */}
                </div>
                <h4
                //   className="wlt-price"
                >
                  {wallet?.balance?.toFixed(2) || "10"} {currency}
                </h4>
              </div>
            </Col>

            <Col span={24} md={12} xl={8}>
              <div className="walletbox-card">
                <div className="wlt-header">
                  <img width={50} src={GoldIcon} />
                  <p>Available Gold</p>
                </div>
                <h4>
                  {userProfile?.gold_stock || "0"} {"KG"}
                </h4>
              </div>
            </Col>

            <Col span={24} md={12} xl={8}>
              <div className="walletbox-card">
                <div className="wlt-header">
                  <p>Transaction Limit</p>
                </div>
                <h4
                //   className="wlt-price"
                >
                  {userProfile?.todays_gold}
                  {"/"}
                  {userProfile?.cap + " KG"} (
                  {Math.round(
                    (userProfile?.todays_gold / userProfile?.cap) * 100
                  )}{" "}
                  %)
                </h4>
                <Tooltip
                  title={
                    Math.round(
                      (userProfile?.todays_gold / userProfile?.cap) * 100
                    ) + " %"
                  }
                >
                  <Progress
                    className="lavel-bar"
                    success={{
                      percent:
                        (userProfile?.todays_gold / userProfile?.cap) * 100,
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>

          {currency !== "USD" && (
            <Row gutter={[24, 24]} className="mt-3">
              <Col xs={24} xl={16} className="mb-24">
                <ChartGold currency={currency} />
              </Col>

              <Col span={24} xl={8}>
                <Col span={24}>
                  <Card className="home-card">
                    <div className="qnty-box">
                      <div className="up-dawn-errow">
                        {isRise ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined style={{ color: "red" }} />
                        )}
                      </div>
                      <h6>
                        XAU-{currency}{" "}
                        {/* <Link
                          to={"/available-currencies"}
                          style={{
                            fontSize: "14px",
                            color: "grey",
                            textDecoration: "underline",
                            marginTop: "-5px",
                          }}
                        >
                          (View Other Currencies)
                        </Link> */}
                      </h6>
                      <div className="qnty-price">
                        {currency} {bookAmount?.toFixed(2)}
                        {"/Oz"}
                      </div>
                      <Button
                        className="price-buy"
                        onClick={() => {
                          if (!quantity || quantity < 1) {
                            setError("Enter valid quantity in kg!");
                          } else if (
                            parseInt(userProfile?.todays_gold) +
                            parseInt(quantity) >
                            parseInt(userProfile?.cap)
                          ) {
                            setError("Purchase limit exceeded!");
                          } else if (
                            parseInt(quantity) * bookAmount * 32.148 >
                            parseInt(wallet?.balance)
                          ) {
                            setError("Not Enough Balance!");
                          } else {
                            latestPrice();
                            setIsModalOpen(true);
                            setError("");
                          }
                        }}
                      >
                        Buy XAU
                      </Button>
                      <div className="quantity-pricebox">
                        <Input
                          min={1}
                          type="number"
                          placeholder="Enter Quantity in KG"
                          value={quantity}
                          onChange={({ target }) => {
                            setQuantity(target.value);
                            // handleQntyChange(target.value);
                          }}
                          className="quantity-inputs"
                        />
                      </div>
                      {error && (
                        <span style={{ color: "red", fontSize: 15 }}>
                          {error}
                        </span>
                      )}
                      <div className="homex-outerr"></div>
                    </div>
                  </Card>
                </Col>
              </Col>
            </Row>
          )}

          <Row gutter={[24, 24]} className="mt-3">
            <Col xs={24} xl={16} className="mb-24">
              <ChartGold currency={"USD"} />
            </Col>

            {currency == "USD" && (
              <Col span={24} xl={8}>
                <Col span={24}>
                  <Card className="home-card">
                    <div className="qnty-box">
                      <div className="up-dawn-errow">
                        {isRise ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined style={{ color: "red" }} />
                        )}
                      </div>
                      <h6>
                        XAU-{currency}{" "}
                        {/* <Link
                          to={"/available-currencies"}
                          style={{
                            fontSize: "14px",
                            color: "grey",
                            textDecoration: "underline",
                            marginTop: "-5px",
                          }}
                        >
                          (View Other Currencies)
                        </Link> */}
                      </h6>
                      <div className="qnty-price">
                        {currency} {bookAmount?.toFixed(2)}
                        {"/Oz"}
                      </div>
                      <Button
                        className="price-buy"
                        onClick={() => {
                          if (!quantity || quantity < 1) {
                            setError("Enter valid quantity in kg!");
                          } else if (
                            parseInt(userProfile?.todays_gold) +
                            parseInt(quantity) >
                            parseInt(userProfile?.cap)
                          ) {
                            setError("Purchase limit exceeded!");
                          } else if (
                            parseInt(quantity) * bookAmount * 32.148 >
                            parseInt(wallet?.balance)
                          ) {
                            setError("Not Enough Balance!");
                          } else {
                            latestPrice();
                            setIsModalOpen(true);
                            setError("");
                          }
                        }}
                      >
                        Buy XAU
                      </Button>
                      <div className="quantity-pricebox">
                        <Input
                          min={1}
                          type="number"
                          placeholder="Enter Quantity in KG"
                          value={quantity}
                          onChange={({ target }) => {
                            setQuantity(target.value);
                            // handleQntyChange(target.value);
                          }}
                          className="quantity-inputs"
                        />
                        {/* <Select
                        className="price-select"
                        defaultValue={userProfile?.gold_purity}
                        onChange={(value) => {
                          setQuality(value);
                        }}
                        disabled={true}
                        options={[
                          { value: "995", label: "995" },
                          { value: "999", label: "999" },
                        ]}
                      /> */}
                      </div>
                      {error && (
                        <span style={{ color: "red", fontSize: 15 }}>
                          {error}
                        </span>
                      )}
                      <div className="homex-outerr"></div>
                    </div>
                  </Card>
                </Col>
              </Col>
            )}
          </Row>

          <Row gutter={[24, 24]} className="mt-3">
            <Col xs={24} className="sm-padding-0 ">
              <Card className=" table-card">
                <SectionWrapper
                  cardHeading={"Purchase Orders"}
                  className="section-tital"
                >
                  <div className="table-responsive customPagination withOutSearilNo">
                    <Table
                      className="main-table"
                      columns={columns}
                      dataSource={orders}
                      pagination={true}
                    />
                  </div>
                </SectionWrapper>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {isModalOpen && (
        <ConfirmOrder
          data={{
            bookAmount,
            quality: userProfile?.gold_purity,
            quantity,
            currency,
          }}
          latestPrice={latestPrice}
          show={isModalOpen}
          placeOrder={placeOrder}
          onOk={() => {
            setIsModalOpen(false);
            setDone(false);
            setQuantity();
          }}
          flag={done}
        />
      )}

      <Modal
        open={walletModal}
        width={300}
        onCancel={() => setWalletModal(false)}
        okText={"Add"}
        cancelText={"Cancel"}
        footer={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        title="Change Wallet"
        className="tab_modal "
      >
        <Form id="create" layout="vertical">
          <div className="price-n-text">
            <Select
              className="price-select"
              style={{ minWidth: "100%" }}
              onChange={(value) => {
                console.log(value);
                setSelectedWallet(value);
              }}
              placeholder="Select Wallet"
            >
              {otherWallets?.map((item, idx) => {
                return (
                  <Option key={idx} value={item?._id}>
                    {item?.currency}
                    {" - "}
                    {item?.balance?.toFixed(2)}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              style={{
                marginTop: 15,
                width: "50%",
                marginInline: "auto",
              }}
              onClick={() => {
                fetchWalletInfo(selectedWallet);
                setWalletModal(false);
              }}
              className="price-buy"
            >
              Confirm
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default Home;
