import React, { useState, useEffect } from "react";
import { message, Upload as UploadAntd, Image , Button} from "antd";
import Camera from "../../assets/images/camera.png";
import Avatar from "../../assets/images/avatar-1.png";
import EditIcon from "../../assets/images/pencil-fill.svg"
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import {EditOutlined, UploadOutlined} from "@ant-design/icons"

const s3Config = {
  region: "us-east-1",
  credentials: {
    accessKeyId: "IJTXH751MBXUWMTWM0SF",
    secretAccessKey: "lfTPRJ2PqAxsp3poTW9YlHktgaL1cFkLup8LidW9",
  },
  endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/",
  forcePathStyle: true,
};
const s3Client = new S3Client(s3Config);

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const UploadImage = ({ setImage, value, disabled = false, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const uploadFileToS3 = async (file, bucketName) => {
    const key = "trading/user/" + file.name;
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
    };

    const upload = new Upload({
      client: s3Client,
      params: params,
    });

    try {
      const data = await upload.done();
      console.log(data);
      return data;
    } catch (err) {
      throw err;
    }
  };

  const handleImgChange = async (event) => {
    const { file } = event;

    try {
      const data = await uploadFileToS3(file, "invent-colab-obj-bucket");
      const fileData = {
        uid: file.uid,
        name: file.name,
        status: "done",
        url: data.Location,
        thumbUrl: data.Location,
      };
      setImageUrl(data.Location);
      setImage(data.Location);
      setLoading(false);
      if (onChange) {
        console.log(data);
        onChange(data.Location);
      }
      //   setFile([fileData]);
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };

  const uploadButton = (
    <div>
      <div className="user-upload-ic">
        <img src={Camera} />
      </div>
      <div className="default_img">
        <img src={Avatar} />
      </div>
    </div>
  );

  useEffect(() => {
    console.log(value, "url");
    if (!value) return;
    setImageUrl(value);
  }, [value, loading]);

  return (
    <UploadAntd
      disabled={disabled}
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={handleImgChange}
    >
      {imageUrl ? (
        <> 
        <Button className="upload-btn">  
          <img src={EditIcon} />
        </Button>
          <Image
            preview={disabled}
            src={imageUrl}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        </>
      ) : (
        uploadButton
      )}
    </UploadAntd>
  );
};

export default UploadImage;
